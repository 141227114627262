/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable semi */
/* eslint-disable space-before-function-paren */
/* eslint-disable comma-dangle */
/* eslint-disable arrow-parens */

// vue
import { createApp } from 'petite-vue'
// fancyapps
import '@fancyapps/ui/dist/fancybox.esm'
import '@fancyapps/ui/dist/fancybox.css'
// bootstrap icons
import 'bootstrap-icons/font/bootstrap-icons.css'
import Cookies from 'js-cookie'
// countUp
import initCountUp from './countup'
// gsap
import initGSAP from './gsap'
// swiper
import initSwiper from './swiper'
// tailwindcss
import '../css/input.css'

// import {
//   getAllIndustry, getAllApp, getAllFun, getAllProd, getSomeTable,
// } from './api';

// getSomeApp, getSomeFun, getSomeProd,
createApp({
  // data
  loadingToggle: true,
  requesingToggle: false,
  sidebarToggle: false,
  contactToggle: false,
  searchToggle: false,
  videoToggle: false,
  videoSrc: '',
  allTables: [],
  totalTables: 0,
  someTable: [],
  allIndustrys: {},
  iptValue: '',
  someApps: {},
  someFuns: {},
  someProds: {},
  industrys: {},
  tableHtml: '',
  apps: {},
  funs: {},
  prods: {},
  mobileMenuToggle: false,
  toPosi: Function,
  zoomIn: Function,
  zoomOut: Function,
  theSwiper: null,
  theSwiperSelectIndex: null,
  playV: true,
  showOne: 0,
  aggIndToggle: false,
  aggProIndex: 0,
  aggFAQIndex: 0,
  search_page_nav: 0,
  // iptValue: '',
  x: '50%',
  y: '50%',
  copyTxt(textV, alertV) {
    const text = textV
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.style.position = 'absolute'
    textarea.style.opacity = '0'
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
    window.alert(alertV)
  },
  toPosiFn() {
    this.toPosi()
  },
  ToSwiperIndexFn(i, num = 1) {
    this.theSwiper.slideTo(i / num)
  },
  handleDisabled(cur) {
    if (Array.isArray(cur) && cur.length === 0) {
      return false
    }
    if (Object.keys(cur).length === 0) {
      return 'disabled'
    }
    return false
  },
  toggleMore(compareItems, overHeight) {
    compareItems.forEach(compareItem => {
      if (compareItem.clientHeight >= overHeight) {
        compareItem.classList.add('toggleText')
      }
    })
  },
  // handleChangeTable() { // indu, app, fun, prod
  //   getSomeTable({
  //     action: 'get_product_data',
  //     posts_per_page: -1,
  //     industry: this.industrys,
  //     application: this.apps,
  //     function: this.funs,
  //     product_group: this.prods,
  //     s: this.iptValue,
  //   }).then((res) => {
  //     this.tableHtml = res.data.html;
  //     this.totalTables = res.data.total;
  //     this.someTable = res.data.data;
  //     this.requesingToggle = false;
  //   });
  // },
  // handleIndustry(cur) {
  //   this.requesingToggle = true;
  //   this.industry = cur;
  //   // this.handleChangeTable();
  // },
  // handleApps(cur) {
  //   this.requesingToggle = true;
  //   this.apps = cur;
  //   // this.handleChangeTable();
  // },
  // handleFuns(cur) {
  //   this.requesingToggle = true;
  //   this.funs = cur;
  //   // this.handleChangeTable();
  // },
  // handleProds(cur) {
  //   this.requesingToggle = true;
  //   this.prods = cur;
  //   // this.handleChangeTable();
  // },
  controlVideo(e) {
    if (this.playV) {
      e.currentTarget.querySelector('video').play()
    } else {
      e.currentTarget.querySelector('video').pause()
    }
    this.playV = !this.playV
    this.selfActiveToggleFn(e)
  },
  docToggleFn(val) {
    if (val) {
      document.querySelector('body').classList.add('doc--active')
    } else {
      document.querySelector('body').classList.remove('doc--active')
    }
  },
  mobileMenuToggleFn(e, toggle) {
    this.popAnimationFn(e, toggle, '#mobile-menu', 'mobileMenu', 'left')
  },
  parentActiveAddFn(e, str = 'active') {
    e.currentTarget.parentElement.classList.add(str)
  },
  parentRemoveClassFn(e, str = 'active') {
    e.currentTarget.parentElement.classList.remove(str)
  },
  parentActiveToggleFn(e, str = 'active') {
    e.currentTarget.parentElement.classList.toggle(str)
  },
  grandParentActiveToggleFn(e, str = 'active') {
    e.currentTarget.parentElement.parentElement.classList.toggle(str)
  },
  grandParentRemoveFn(e, str = 'active') {
    e.currentTarget.parentElement.parentElement.classList.remove(str)
  },
  grandParentAddFn(e, str = 'active') {
    e.currentTarget.parentElement.parentElement.classList.add(str)
  },
  oneFlag: 0,
  toggleOneFn(index) {
    this.oneFlag = index
    this.ToSwiperIndexFn(index)
  },
  parentAddClassFn(e, str = 'active') {
    e.currentTarget.parentElement.classList.add(str)
  },
  selfActiveToggleFn(e, str = 'active') {
    e.currentTarget.classList.toggle(str)
  },
  childActiveToggleFn(e) {
    e.currentTarget.querySelector('.drop_down_icon').classList.toggle('active')
  },
  removeParentActiveFn(e, str = 'active') {
    e.currentTarget.parentElement.classList.remove(str)
  },
  removeActiveFn(e, str = 'active') {
    e.currentTarget.classList.remove(str)
  },
  removeTheChildActiveFn(e) {
    e.currentTarget.children[0].classList.remove('active')
  },
  contactToggleFn(e, toggle) {
    this.popAnimationFn(e, toggle, '#pop-form', 'contact')
  },
  searchToggleFn(e, toggle) {
    this.popAnimationFn(e, toggle, '#pop-search', 'search')
  },
  videoToggleFn(e, toggle) {
    this.popAnimationFn(e, toggle, '#pop-video', 'video')
  },
  popAnimationFn(e, toggle, selector, state, posi) {
    if (e) {
      this.x = e.clientX
      this.y = e.clientY
    }
    if (toggle) {
      if (selector === '#pop-video') {
        this.videoSrc = e.currentTarget.dataset.src
      }
      this.docToggleFn(toggle)
      this[`${state}Toggle`] = toggle
      this.zoomIn(selector, this.x, this.y, posi)
    } else {
      if (selector === '#pop-video') {
        this.videoSrc = ''
      }
      this.zoomOut(selector, this.x, this.y, posi)
      const timeId = setTimeout(() => {
        this.docToggleFn(toggle)
        this[`${state}Toggle`] = toggle
        this.x = '50%'
        this.y = '50%'
        clearTimeout(timeId)
      }, 480)
    }
  },

  // mounted
  mounted() {
    var items = document.querySelectorAll('.bdmap .mark .item')
    console.log('items', items)
    items?.forEach(item => {
      item.addEventListener('click', () => {
        // 移除所有item的'active'类名
        items.forEach(otherItem => {
          otherItem.classList.remove('active')
        })
        // 为当前点击的item添加'active'类名
        item.classList.add('active')
      })
    })

    // items.forEach(item => {
    //   item.addEventListener('click', () => {
    //     items.forEach(otherItem => {
    //       if (otherItem !== item) {
    //         otherItem.classList.remove('active')
    //       }
    //     })
    //     item.classList.add('active')
    //   })
    // })
    // cookie 判断  popAdFirst=1;
    // 初次进入时判断有没有popAdFirst=1；1-有的话就不需要再给showImg的类名；2-没有的话给弹出类名并且给cookie设置这个值。
    let popAdFirstV = Cookies.get('popAdFirst')
    if (!popAdFirstV) {
      document.getElementById('popAd')?.classList.add('showImg')
      Cookies.set('popAdFirst', 'first')
    } else {
      document.getElementById('popAd')?.classList.remove('showImg')
    }
    // cookie 判断
    document
      .querySelector(
        '.page_prodFinder .flex.items-center a.block-icon:last-child'
      )
      ?.addEventListener('click', () => {
        this.iptValue = document.querySelector('form input.orig')?.value
        let urlTxt = `${window.location.origin}${
          window.location.pathname
        }search/${this.iptValue ? this.iptValue : '0'}`
        let alertText = 'The url is transferred to the clipboard.'
        this.copyTxt(urlTxt, alertText)
      })
    if (document.querySelector('.ifShowButton .button')) {
      const descBoxs = document.querySelectorAll('.ifShowButton .desc')
      this.toggleMore(descBoxs, 144)
    }
    this.loadingToggle = false
    // if (document.querySelector('#finder-form')) {
    //   this.searchValue = document.querySelector('form input.orig')?.getAttribute('search');
    //   this.iptValue = this.searchValue;
    //   document.querySelector('form input.orig').value = this.searchValue;
    //   // 判断如果已经有php传入的某个值，在这里也给赋值industrys的值。
    //   this.requesingToggle = true;
    //   getAllIndustry({ action: 'get_industry_data' }).then((res) => {
    //     this.allIndustrys = res.data;
    //     let initTotal = document.querySelector('.page_prodFinder .total>span')?.getAttribute('total');
    //     console.log('initTotal', initTotal);
    //     this.totalTables = initTotal;
    //     this.requesingToggle = false;
    //     if (document.querySelector('#industrys-input p').getAttribute('industry')) {
    //       this.industrys = document.querySelector('#industrys-input p').getAttribute('industry');
    //       // this.handleIndustry();
    //     }
    //     // this.handleChangeTable();
    //   });
    //   getAllApp().then((res) => {
    //     this.someApps = res.data;
    //     this.requesingToggle = false;
    //   });
    //   getAllFun().then((res) => {
    //     this.someFuns = res.data;
    //     this.requesingToggle = false;
    //   });
    //   getAllProd().then((res) => {
    //     this.someProds = res.data;
    //     this.requesingToggle = false;
    //   });
    // }
    // initSwiper
    const swiper = initSwiper()
    this.theSwiper = swiper.aboutCorporSwiper
    this.theSwiperSelectIndex = swiper.theSwiperIndex
    // initCountUp
    initCountUp()
    // initGSAP
    const gsapObj = initGSAP()
    this.toPosi = gsapObj.scrollTo
    this.zoomIn = gsapObj.zoomIn
    this.zoomOut = gsapObj.zoomOut
    // scroll
    let ticking = false
    let { scrollY } = window
    window.addEventListener('scroll', () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          // do something...
          if (scrollY > 50) {
            this.sidebarToggle = true
          } else {
            this.sidebarToggle = false
            document.getElementById('popAd')?.classList.remove('hideImg')
          }
          scrollY = window.scrollY
          ticking = false
        })
        ticking = true
      }
    })
  }
}).mount('#app')
